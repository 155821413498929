/*
 * @Author: 哈库呐玛塔塔
 * @Date: 2022-11-14 16:55:42
 * @Descripttion: 
 * @LastEditors: 哈库呐玛塔塔
 * @LastEditTime: 2022-11-16 17:25:20
 */
import React, { useContext, useEffect, useState } from "react"
import MdWrapper from "../../../../../components/MarkdownWrap"
import { useLocation } from "@reach/router"
import { LangSetStateContext } from "../../../../../context/langContext"
import Axios from "axios"


const Privacy = () => {
  const [md, setMd] = useState(null)
  const location = useLocation()


  const setLang = useContext(LangSetStateContext)

  useEffect(() => {
    document.documentElement.lang = "ja"
    getMd()

  }, [])
  const getMd = () => {
    Axios.get(`${process.env.resourceUrl}docs/nebula_h5_docs/pp/kddi/PrivacyPolicy-JA.md`).then(function (data) {
      setMd(data.data)
    })

  }
  return (
    // <Layout isDocPage={false} menuBlack={true}>
    //   <SEO page="Privacy Policy" />
    <MdWrapper md={md} blankLayout />
    // </Layout>
    // <div style={{ backgroundColor: "#f4f7f8 !important" }} />
  )
}

export default Privacy
